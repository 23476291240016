@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/FrankRuhlLibre-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/FrankRuhlLibre-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Pretendard-Regular.woff2') format('woff2');
  /* 한글 유니코드 범위 */
  unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
}

@font-face {
  font-family: 'Pretendard';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Pretendard-Bold.woff2') format('woff2');
  /* 한글 유니코드 범위 */
  unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
}

@font-face {
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/PretendardJP-Regular.woff2') format('woff2');
  /* 한자, 일본어 유니코드 범위 */
  unicode-range: 
     /* 한글 */ U+AC00-D7AF, /* 한자 */ U+2F800-2FA1F,
    U+20000-2A6DF, U+F900-FAFF, U+4E00-9FBF, U+3400-4DBF, U+2E80-2EFF,
    /* 일본어 (특수문자 제외) */ U+3040-3000, U+3003-300B, U+300E-309F,
    U+30A0-30FA, U+30FC-30FF, U+31F0-31FF, /* 영문 대문자 */ U+0041-005A,
    /* 영문 소문자 */ U+0061-007A, /* 숫자 (특수문자 제외) */ U+0030-0024,
    U+0027-0029, U+002B-002E, U+0031-0039;
}

@font-face {
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/PretendardJP-Bold.woff2') format('woff2');
  /* 한자, 일본어 유니코드 범위 */
  unicode-range: 
     /* 한글 */ U+AC00-D7AF, /* 한자 */ U+2F800-2FA1F,
    U+20000-2A6DF, U+F900-FAFF, U+4E00-9FBF, U+3400-4DBF, U+2E80-2EFF,
    /* 일본어 (특수문자 제외) */ U+3040-3000, U+3003-300B, U+300E-309F,
    U+30A0-30FA, U+30FC-30FF, U+31F0-31FF, /* 영문 대문자 */ U+0041-005A,
    /* 영문 소문자 */ U+0061-007A, /* 숫자 (특수문자 제외) */ U+0030-0024,
    U+0027-0029, U+002B-002E, U+0031-0039;
}

@font-face {
  font-family: 'PretendardNumber';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Pretendard-Regular.woff2') format('woff2');
  /* 숫자 유니코드 범위 */
  unicode-range: U+0030-0039;
}

@font-face {
  font-family: 'PretendardNumber';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Pretendard-Bold.woff2') format('woff2');
  /* 숫자 유니코드 범위 */
  unicode-range: U+0030-0039;
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Poppins-Italic.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Poppins-BoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Poppins-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Poppins-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Tangerine';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Tangerine-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Megista Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/megista-display-regular.woff2') format('woff2');
}
